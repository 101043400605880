<template>
  <v-dialog v-model="dialog" max-width="400px" @keydown.esc="cancel" persistent>
    <v-form ref="form" class="pa-6 text-start">
      <div class="d-flex mb-3 text-h6">Delete pool ?</div>
      <div class="text-body-2">The pool will be deleted and the token will be refunded afterwards. Are you sure?</div>
      <div class="d-flex justify-space-between mt-6">
        <v-btn outlined rounded min-width="100px" @click="dialog = false">Cancel</v-btn>
        <v-btn class="primary" rounded min-width="100px" @click="save" :loading="loading">Delete</v-btn>
      </div>
    </v-form>
  </v-dialog>
</template>
      
<script>
import { mapState } from 'vuex'
export default {
  props: {
    state: Boolean,
    claim: Object,
  },
  components: {},
  computed: {
    ...mapState('auth', ['multiClaimHandler']),
  },
  methods: {
    async save() {
      try {
        this.loading = true
        if (!this.$refs.form.validate()) return
        const res = await this.multiClaimHandler.closeClaim(this.claim.id)
        if (res) {
          this.$emit('updateDone', true)
          this.$alert.success('Close pool successed')
        } else {
          this.$alert.error('Close pool failed')
        }
        this.dialog = false
      } catch (e) {
        this.$alert.error(e.message)
      } finally {
        this.loading = false
      }
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    state(state) {
      this.dialog = true
    },
  },
}
</script>